import React from 'react';
import { Tooltip, Button } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as RaisedHandSvg } from '../../raisedHand.svg';

const HandRaiseControl = ({ isHandRaised, toggleRaiseHand }) => {
  // Tooltip message based on whether the hand is raised or not
  const getTooltipMessage = () => (isHandRaised ? 'Lower Hand' : 'Raise Hand');

  // Style for the button based on whether the hand is raised
  const getButtonStyle = () => ({
    backgroundColor: isHandRaised && '#52c41a', // Red if hand is raised, green if lowered
    color: isHandRaised && '#fff',
  });

  return (
    <Tooltip title={getTooltipMessage()}>
      <Button
        size="large"
        shape="circle"
        icon={<Icon component={RaisedHandSvg} />}
        onClick={toggleRaiseHand} // Call the function passed from the parent
        style={getButtonStyle()} // Apply the button style based on state
      />
    </Tooltip>
  );
};

export default HandRaiseControl;
