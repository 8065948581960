import React, { useEffect, useState } from "react";
import { notification } from "antd";

const useMicrophoneChangeListener = () => {
  const [hasMicrophone, setHasMicrophone] = useState(true);

  useEffect(() => {
    // Function to check for available microphones
    const checkMicrophone = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();

      // Check if any audio input (microphone) device is available
      const hasMic = devices.some((device) => device.kind === "audioinput");

      // Update state and notify if no microphone is available
      setHasMicrophone(hasMic);

      if (!hasMic) {
        // notification.error({
        //   message: "Microphone Disconnected",
        //   description: "Please connect a microphone to continue the seminar.",
        // });
      }
    };

    // Listen to device changes (when microphone is plugged in or removed)
    navigator.mediaDevices.addEventListener("devicechange", checkMicrophone);

    // Perform initial check for microphones when component mounts
    checkMicrophone();

    // Clean up the event listener when the component unmounts
    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", checkMicrophone);
    };
  }, []);

  return { hasMicrophone };
};

export default useMicrophoneChangeListener;
