import React, { useState } from "react";
import { Modal, Button, Alert, Typography, Spin } from "antd";
import { usePermission } from "./PermissionContext";
import { LoadingOutlined } from "@ant-design/icons";
import Seminar from "../Seminar/Index";

const { Text, Title } = Typography;

const PermissionCheck = () => {
  const {
    audioPermissionGranted,
    videoPermissionGranted,
    checkAudioPermission,
    checkVideoPermission,
    isModalOpen,
    handleCancel,
  } = usePermission();
  const [isChecking, setIsChecking] = useState(false);

  // Handle recheck permissions
  // const handleRecheckPermissions = async () => {
  //   setIsChecking(true);
  //   await checkPermissions();
  //   setIsChecking(false);
  // };

  // Custom spin icon
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  return (
    <div className="permission-container">
      {/* Show notifications about permission status, but proceed to Seminar regardless */}
      {/* {permissionGranted === null && (
        <div className="center-content">
          <Spin indicator={antIcon} />
          <Title level={2} className="permission-title">
            Checking permissions...
          </Title>
        </div>
      )} */}

      {/* Seminar will be loaded even if permissions are denied */}
      <Seminar />

      {/* Show alert if permissions are denied */}
      {/* {permissionGranted === false && (
        <div className="center-content">
          <Alert
            message="Permissions Denied"
            description="Please allow access to continue. You can manually enable permissions by clicking the padlock icon in your browser's address bar."
            type="error"
            showIcon
            style={{ marginBottom: "20px" }}
          />
          <Button
            type="primary"
            onClick={handleRecheckPermissions}
            disabled={isChecking}
            className="recheck-button"
          >
            {isChecking ? <Spin size="small" /> : "Recheck Permissions"}
          </Button>
        </div>
      )} */}

      {/* Ant Design Modal for permission denied alert */}
      {/* <Modal
        title="Permission Required"
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        footer={[
          <Button
            key="recheck"
            type="primary"
            onClick={handleRecheckPermissions}
            disabled={isChecking}
          >
            {isChecking ? <Spin size="small" /> : "Recheck Permissions"}
          </Button>,
          <Button key="close" type="danger" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <div>
          <Text strong style={{ fontSize: 16, color: "#595959" }}>
            Access to your microphone and camera is required to join the
            seminar. Please follow these steps to allow access:
          </Text>
          <ol style={{ marginTop: 10 }}>
            <li>Click the padlock icon next to the URL in your browser.</li>
            <li>Select "Site Settings."</li>
            <li>Find the Camera and Microphone settings and allow access.</li>
          </ol>
          <Text style={{ display: "block", marginTop: 20 }}>
            After allowing access, you can click the "Recheck Permissions"
            button below.
          </Text>
        </div>
      </Modal> */}
    </div>
  );
};

export default PermissionCheck;
