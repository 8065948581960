import React from 'react';
import { Tooltip, Button } from 'antd';
import { ReactComponent as VideoStartSvg } from '../../videoStart.svg'; 
import { ReactComponent as VideoStopSvg } from '../../VideoStop.svg'; 
import Icon from '@ant-design/icons';
import { usePermission } from "../../Permissions/PermissionContext";

const VideoControl = ({ videoMuted, toggleVideo }) => {
  const { videoPermissionGranted } = usePermission();

  // Tooltip message based on video permission and state
  const getTooltipMessage = () => {
    if (!videoPermissionGranted) {
      return "Camera access required";
    }
    return videoMuted ? "Start Video" : "Stop Video";
  };

  // Style for button based on permission and video muted state
  const getButtonStyle = () => {
    if (!videoPermissionGranted) {
      return {
        backgroundColor: "#ffcccc", // Light red when disabled
        color: "#fff",
        borderColor: "#ffcccc", // Border matches the background
      };
    }

    return {
      backgroundColor: videoMuted ? "#f5222d" : "#52c41a", // Red if video is muted, green if unmuted
      color: "#fff",
      borderColor: videoMuted ? "#f5222d" : "#52c41a",
    };
  };

  return (
    <Tooltip title={getTooltipMessage()}>
      <Button
        size="large"
        shape="circle"
        icon={videoMuted ? <Icon component={VideoStopSvg} /> : <Icon component={VideoStartSvg} />}
        onClick={toggleVideo}
        danger={videoMuted}
        disabled={!videoPermissionGranted} // Disable if video permission is denied
        style={getButtonStyle()}
      />
    </Tooltip>
  );
};

export default VideoControl;
