import PermissionCheck from "./Permissions/PermissionCheck";
import { PermissionProvider } from "./Permissions/PermissionContext";
import { ConfigProvider } from "antd";

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#fe6d29",
          },
        }}
      >
        <PermissionProvider>
          <PermissionCheck />
        </PermissionProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
