import React, { useEffect, useState } from "react";
import { Button, Flex, Row } from "antd";
import ParticipantItem from "./ParticipantItem";
import axios from "axios";
import { CloseOutlined } from "@ant-design/icons";

const ParticipantList = ({
  participants,
  onPinParticipant,
  muteParticipant,
  unmuteParticipant,
  getQueryParams,
  setParticipantDataChat,
  onClose,
}) => {
  // State to store participant data, using userId as key for caching
  const [participantDataCache, setParticipantDataCache] = useState({});

  const fetchRemoteUser = async (userId) => {
    const { token } = getQueryParams();

    try {
      const response = await axios.get(
        `https://api.gorillafilm.com/api/user/user-info`,
        {
          params: { user_id: userId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data; // Return the fetched user data
    } catch (err) {
      console.error("Error fetching user details:", err);
    }
  };

  useEffect(() => {
    const fetchParticipantData = async () => {
      const updatedParticipants = await Promise.all(
        participants.map(async (participant) => {
          // Check if user data already exists in state (cache)
          if (participantDataCache[participant.uid]) {
            return {
              ...participant,
              name: participantDataCache[participant.uid].name,
              profileImage: participantDataCache[participant.uid].profileImage,
            };
          }

          // If not cached, fetch user data from API
          const userData = await fetchRemoteUser(participant.uid);
          const updatedParticipant = {
            ...participant,
            name: userData?.user_info?.full_name || "Unknown",
            profileImage: userData?.user_info?.profile_photo || null,
          };

          // Store fetched user data in state (cache)
          setParticipantDataCache((prevCache) => ({
            ...prevCache,
            [participant.uid]: updatedParticipant,
          }));

          return updatedParticipant;
        })
      );

      // Set the participant data
      setParticipantDataCache((prevCache) => ({
        ...prevCache,
        ...Object.fromEntries(updatedParticipants.map((p) => [p.uid, p])),
      }));
    };

    fetchParticipantData();

    // Play video for users whose video is not muted
    participants.forEach((participant) => {
      if (!participant.videoMuted && participant.videoTrack) {
        participant.videoTrack.play(`remote-video-${participant.uid}`);
      }
    });
  }, [participants]);

  useEffect(() => {
    setParticipantDataChat(participantDataCache);
  }, [participantDataCache]);

  return (
    <div
      className="audience-panel"
      style={{
        padding: "10px",
        overflowY: "auto",
        height: "calc(100vh - 60px)",
      }}
    >
      <Flex justify={"space-between"} align={"center"}>
        <h3>Participants</h3>
        <Button
          icon={<CloseOutlined />}
          danger
          type="primary"
          shape="circle"
          onClick={onClose}
        />
      </Flex>
      <Row gutter={[16, 16]}>
        {participants.map((participant, index) => (
          <ParticipantItem
            key={index}
            participant={participantDataCache[participant.uid] || participant}
            onPin={() => onPinParticipant(participant)}
            muteParticipant={muteParticipant}
            unmuteParticipant={unmuteParticipant}
            getQueryParams={getQueryParams}
          />
        ))}
      </Row>
    </div>
  );
};

export default ParticipantList;
