import React from "react";
import { Tooltip, Button } from "antd";
import { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import { flushSync } from "react-dom";

const AudioControl = ({
  audioMuted,
  toggleAudio,
  isMuteByAdmin,
  audioPermissionGranted,
  isAudioLoader,
  setIsAudioLoader
}) => {
  // Tooltip message based on audio permission and state
  const getTooltipMessage = () => {
    if (!audioPermissionGranted) {
      return "Microphone access required";
    }
    if (isMuteByAdmin) {
      return "Audio muted by admin";
    }
    return audioMuted ? "Unmute Audio" : "Mute Audio";
  };

  // Style for button based on permission and audio muted state
  const getButtonStyle = () => {
    if (!audioPermissionGranted) {
      return {
        backgroundColor: "#ffcccc", // Light red when disabled
        color: "#fff",
        borderColor: "#ffcccc", // Border matches the background
      };
    }
    return {
      backgroundColor: audioMuted || isMuteByAdmin ? "#f5222d" : "#52c41a", // Red if muted, green if unmuted
      color: "#fff",
      borderColor: audioMuted || isMuteByAdmin ? "#f5222d" : "#52c41a",
    };
  };
  const handleButtonClick = () => {
    flushSync(() => {
      setIsAudioLoader(true); // ye synchronous state update karega
    });
    toggleAudio();
  };
  return (
    <Tooltip title={getTooltipMessage()}>
      <Button
        loading={isAudioLoader}
        shape="circle"
        icon={
          audioMuted || isMuteByAdmin ? (
            <AudioMutedOutlined />
          ) : (
            <AudioOutlined />
          )
        }
        onClick={handleButtonClick}
        danger={audioMuted}
        disabled={!audioPermissionGranted || isMuteByAdmin} // Disable if audio permission is denied
        size="large"
        style={getButtonStyle()}
      />
    </Tooltip>
  );
};

export default AudioControl;
