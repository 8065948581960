import { Button, Tooltip } from "antd";
import { ReactComponent as ScreenShareSvg } from "../../share-screen.svg";
import Icon from "@ant-design/icons";

const ScreenShareControl = ({ isSharing, toggleScreenShare }) => (
  <Tooltip title={'Screen share'}>
  <Button
    size="large"
    shape="circle"
    onClick={toggleScreenShare}
    icon={<Icon component={ScreenShareSvg} />}
  ></Button>
  </Tooltip>
);
export default ScreenShareControl;
