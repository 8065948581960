import React, { useEffect } from "react";
import { Avatar, Tooltip, Button, Card, Col } from "antd";
import {
  AudioMutedOutlined,
  AudioOutlined,
  PushpinOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

const ParticipantItem = ({
  participant,
  onPin,
  muteParticipant,
  unmuteParticipant,
  getQueryParams,
}) => {
  const { uid, videoMuted, videoTrack, audioMuted, name, profileImage } =
    participant;

  const isHost = getQueryParams().role == "host";

  // Use effect to play video if video is not muted and track exists
  useEffect(() => {
    if (!videoMuted && videoTrack) {
      const videoElementId = `remote-video-${uid}`;
      const videoElement = document.getElementById(videoElementId);

      if (videoElement) {
        // Play the video in the div if it's available
        videoTrack.play(videoElementId, { fit: "contain" }); // Use Agora's fit option to cover the area
      } else {
        console.error(`Video element not found for user ${uid}`);
      }
    }
    console.log("participant", participant);
  }, [videoMuted, videoTrack, uid, participant]);

  const initials = (name || "Unknown")
    .split(" ")
    .map((n) => n[0])
    .join("");

  return (
    <Col xs={12} sm={12} md={12}>
      <Card
        //onClick={onPin} // Pin participant when clicked
        title={
          <>
            <span>
              {name} 
              {/* {isHost && participant.isLocal && <strong>(Host)</strong>} */}
            </span>
            <Tooltip
              title={audioMuted ? "Audio Muted" : "Audio Unmuted"}
              key="audio"
            >
              {!participant.isLocal ? (
                audioMuted ? (
                  <AudioMutedOutlined />
                ) : (
                  <AudioOutlined />
                )
              ) : (
                ""
              )}
            </Tooltip>
          </>
        }
        className="remote-user-card"
        bordered={false}
        styles={{
          header: {
            minHeight: "35px",
          },
          actions: {
            margin: 0,
          },
          body: {
            padding: 0,
            height: 150, // Fixed height for the card body
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        actions={
          isHost && !participant.isLocal
            ? [
                participant?.is_admin_mute ? (
                  <Tooltip title="Un-Mute by admin">
                    <Button
                      icon={<AudioMutedOutlined />}
                      onClick={() => unmuteParticipant(participant)}
                    >
                      {participant?.is_admin_mute
                        ? "Un-Mute by admin"
                        : "Mute by admin"}
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Mute by admin">
                    <Button
                      icon={<AudioOutlined />}
                      onClick={() => muteParticipant(participant)}
                    >
                      {participant?.is_admin_mute
                        ? "Un-Mute by admin"
                        : "Mute by admin"}
                    </Button>
                  </Tooltip>
                ),
              ]
            : []
        }
      >
        <div
          className="video-box"
          id={`remote-video-${uid}`}
          style={{
            width: "100%", // Ensure container takes full width
            height: "100%", // Full height of 150px as set in the card body
            backgroundColor: "#efefef", // Ensure the background is black to mimic a video box
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden", // Prevent video overflow
            position: "relative",
          }}
        >
          {videoMuted && (
            // Show avatar if video is muted
            <>
              <Avatar size={80} src={profileImage || null}>
                {!profileImage ? initials : null}
              </Avatar>
              {/* <div className="remote-user-pin-wrapper">
                <Tooltip title="Pin">
                  <Button shape="circle" icon={<PushpinOutlined />} />
                </Tooltip>
              </div> */}
            </>
          )}
        </div>
      </Card>
    </Col>
  );
};

export default ParticipantItem;
