import React from 'react';
import { Tooltip, Button, Badge } from 'antd';
import { MessageOutlined } from '@ant-design/icons';

const ChatControl = ({ toggleChat,newMessageCount }) => {
  return (
    <Tooltip title="Chat">
      <Badge dot={newMessageCount.length}>
      <Button
        shape="circle"
        size="large"
        icon={<MessageOutlined />}
        onClick={toggleChat}
      />
      </Badge>
    </Tooltip>
  );
};

export default ChatControl;
