import React, { useState, useRef, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Drawer,
  message,
  Avatar,
  Typography,
  Result,
  Spin,
  Card,
  Button,
  Tooltip,
  Badge,
  FloatButton,
  Dropdown,
} from "antd";
import {
  AudioMutedOutlined,
  AudioOutlined,
  CommentOutlined,
  CustomerServiceOutlined,
  MoreOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons";
import { ReactComponent as VideoStartSvg } from "../videoStart.svg";
import { ReactComponent as VideoStopSvg } from "../VideoStop.svg";
import AgoraRTC from "agora-rtc-sdk-ng"; // Import Agora RTC SDK
import AudioControl from "./Controls/AudioControl";
import VideoControl from "./Controls/VideoControl";
import ChatControl from "./Controls/ChatControl";
import FullscreenControl from "./Controls/FullscreenControl";
import HandRaiseControl from "./Controls/HandRaiseControl";
import ParticipantList from "./Layout/ParticipantList";
import HostVideo from "./Layout/HostVideo";
import axios from "axios";
import "../App.css";
import { notification } from "antd";
import useDeviceChangeListener from "../Permissions/useDeviceChangeListener";
import AgoraRTM from "agora-rtm";
import ScreenShareControl from "./Controls/ScreenShareControl";
import ParticipantItem from "./Layout/ParticipantItem";
import { useMediaQuery } from "react-responsive";
import Chat from "./Chat";
import { usePermission } from "../Permissions/PermissionContext";

const { Content, Footer, Sider } = Layout;
const { Title } = Typography;

const SeminarStream = ({
  seminarDetails,
  seminarAgoraToken,
  getQueryParams,
}) => {
  const [audioMuted, setAudioMuted] = useState(true); // Audio muted by default
  const [videoMuted, setVideoMuted] = useState(true); // Video muted by default
  const [isParticipant, setIsParticipant] = useState(true);
  const [isChat, setIsChat] = useState(true);
  const [isFullScreen, setFullScreen] = useState(false);
  const videoContainerRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [agoraJoinRtc, setAgoraJoinRtc] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isSpeaking, setIsSpeaking] = useState(false); // Track if the user is speaking
  const [isMuteByAdmin, setIsMuteByAdmin] = useState(false); // Track if the user is speaking

  const [rtcClient, setRtcClient] = useState(null);
  const [localTracks, setLocalTracks] = useState({
    videoTrack: null,
    audioTrack: null,
  });
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [joined, setJoined] = useState(false);
  const [rtmClient, setRtmClient] = useState(null);
  const [isHandRaised, setIsHandRaised] = useState(false); // Track if the hand is raised
  const timeoutRef = useRef(null); // Reference to store the timeout ID
  const [pinnedUser, setPinnedUser] = useState(null); // To track the pinned participant
  const [screenTrack, setScreenTrack] = useState(null);
  const [isSharing, setIsSharing] = useState(false);
  const [participantDataChat, setParticipantDataChat] = useState([]);
  const [newMessageCount, setNewMessageCount] = useState([]);
  const [screenSharingUserId, setScreenSharingUserId] = useState(null);
  const [localNewAudioTrack, setLocalNewAudioTrack] = useState(null);
  const { audioPermissionGranted } = usePermission();
  const [isAudioLoader, setIsAudioLoader] = useState(false);

  const { hasMicrophone, hasHeadphones } = useDeviceChangeListener(); // Monitor devices
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const userId = seminarDetails?.uid || Math.floor(Math.random() * 10000);
  const channelName = seminarDetails?.channel_uuid;
  const isHost = seminarDetails.role === "host";

  useEffect(() => {
    console.log(
      "audioPermissionGrantedaudioPermissionGranted",
      audioPermissionGranted
    );
  }, [audioPermissionGranted]);
  // Agora setup and event handling
  const initializeAgora = async (appId, channelName, token, uid) => {
    // Step 1: Create Agora RTC Client
    const newRtcClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    setRtcClient(newRtcClient);

    // Step 2: Join the Agora Channel
    try {
      await newRtcClient.join(appId, channelName, token, uid);
      console.log("Successfully joined the Agora channel.");
    } catch (error) {
      console.error("Failed to join Agora channel:", error.message);
      notification.error({
        message: "Connection Error",
        description: "Failed to connect to the seminar. Please try again.",
        duration: 5,
      });
      setAgoraJoinRtc(false);
      setLoading(false);
      return; // Exit early if joining fails
    }

    // Step 3: Enable Audio Volume Indicator
    try {
      newRtcClient.enableAudioVolumeIndicator(); // The interval defaults to 200ms
      console.log("Audio volume indicator enabled.");
    } catch (error) {
      console.error("Failed to enable audio volume indicator:", error.message);
      notification.error({
        message: "Audio Volume Error",
        description:
          "Unable to detect audio levels. Please check your microphone.",
        duration: 5,
      });
    }

    // Step 4: Create and Handle Audio Track
    let audioTrack = null;
    try {
      audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        encoderConfig: "speech_standard",
      }); // Create audio track
      audioTrack.setEnabled(false); // Disable initially
      console.log("Audio track created successfully.");
    } catch (error) {
      console.error(
        "Failed to initialize microphone audio track:",
        error.message
      );
      // notification.error({
      //   message: "Microphone Error",
      //   description:
      //     "Unable to access your microphone. Please check your settings or permissions.",
      //   duration: 5,
      // });
      setAudioMuted(true); // Fallback to muted state if microphone fails
    }

    // Step 5: Create and Handle Video Track
    let videoTrack = null;
    try {
      videoTrack = await AgoraRTC.createCameraVideoTrack(); // Create video track
      videoTrack.setEnabled(false); // Disable initially
      console.log("Video track created successfully.");
    } catch (error) {
      console.error("Failed to initialize camera video track:", error.message);
      // notification.error({
      //   message: "Camera Error",
      //   description:
      //     "Unable to access your camera. Please check your settings or permissions.",
      //   duration: 5,
      // });
    }

    // Step 6: Set Local Tracks in State (if available)
    try {
      console.log("{ audioTrack, videoTrack }", { audioTrack, videoTrack });
      setLocalTracks({ audioTrack, videoTrack });
      console.log("Local tracks set successfully.");
    } catch (error) {
      console.error("Failed to set local tracks:", error.message);
    }

    // Step 7: Publish Audio and Video Tracks (optional)
    try {
      if (audioTrack || videoTrack) {
        await newRtcClient.publish([audioTrack, videoTrack]);
        console.log("Audio and video tracks published successfully.");
      }
    } catch (error) {
      console.error("Failed to publish tracks:", error.message);
      // notification.error({
      //   message: "Publishing Error",
      //   description:
      //     "Failed to publish your audio or video. Please refresh and try again.",
      //   duration: 5,
      // });
    }

    // Step 8: Handle User Events and Finish Setup
    try {
      setJoined(true);
      handleUserEvents(newRtcClient); // Subscribe to user events
      setLoading(false);
      console.log("Agora setup completed successfully.");
    } catch (error) {
      console.error(
        "Error during event handling or final setup:",
        error.message
      );
    }

    // Add local user to remoteUsers state
    const localUser = {
      uid: uid, // Local user ID
      videoTrack: videoTrack,
      audioTrack: audioTrack,
      audioMuted: true,
      videoMuted: true,
      isLocal: true, // Flag to indicate this is the local user
    };

    setRemoteUsers((prevUsers) => [...prevUsers, localUser]);

    initializeRtm(
      "6411478804ef4f838f9f1ca8e5c9aa96",
      channelName,
      seminarDetails.agoraRtmToken,
      userId,
      newRtcClient,
      audioTrack
    );
  };

  useEffect(() => {
    if (screenSharingUserId) {
      const hostUser = remoteUsers.find((user) => {
        return user.uid === screenSharingUserId;
      });
      if (hostUser && hostUser.videoTrack) {
        setPinnedUser(hostUser); // Automatically pin host when screen sharing starts
      }
    } else {
      setPinnedUser(null); // Automatically pin host when screen sharing starts
    }
  }, [remoteUsers, screenSharingUserId]);

  useEffect(() => {
    if (pinnedUser && pinnedUser.videoTrack) {
      const videoElementId = `pinned-video-${pinnedUser.uid}`;

      const playVideo = () => {
        const videoElement = document.getElementById(videoElementId);
        if (videoElement) {
          // Element exists, play the video track on it
          pinnedUser.videoTrack.play(videoElementId, { fit: "contain" });
          message.info("Screen sharing started and displayed.");
        }
      };

      setTimeout(playVideo, 100);

      // Cleanup function to stop video track on unmount or pinnedUser change
      return () => {
        if (pinnedUser.videoTrack) {
          pinnedUser.videoTrack.stop();
        }
      };
    }
  }, [pinnedUser]);

  const handleUserEvents = (client) => {
    // Handle when a user joins the channel
    client.on("user-joined", async (user) => {
      console.log(
        "----------------------user-joined-----------------------",
        user
      );
      setRemoteUsers((prevUsers) => {
        const updatedUsers = prevUsers.filter((u) => u.uid !== user.uid);
        return [
          ...updatedUsers,
          { ...user, audioMuted: true, videoMuted: true },
        ];
      });
    });

    client.on("user-published", async (user, mediaType) => {
      try {
        await client.subscribe(user, mediaType); // Subscribe to the user's media
        if (mediaType === "video") {
          const videoElementId = `remote-video-${user.uid}`;
          const videoElement = document.getElementById(videoElementId);

          if (videoElement) {
            user.videoTrack.play(videoElementId); // Play the video in the target element
          }

          setRemoteUsers((prevUsers) =>
            prevUsers.map((u) =>
              u.uid === user.uid
                ? { ...u, videoMuted: false, videoTrack: user.videoTrack }
                : u
            )
          );
        }

        if (mediaType === "audio") {
          // Set the audio track in state and play it
          setRemoteUsers((prevUsers) => {
            const existingUser = prevUsers.find((u) => u.uid === user.uid);
            if (existingUser) {
              return prevUsers.map((u) =>
                u.uid === user.uid
                  ? { ...u, audioTrack: user.audioTrack, audioMuted: false }
                  : u
              );
            } else {
              return [
                ...prevUsers,
                {
                  uid: user.uid,
                  audioTrack: user.audioTrack,
                  videoTrack: user.videoTrack || null,
                  audioMuted: false,
                },
              ];
            }
          });

          // Play the audio track for the remote user
          user.audioTrack.play();
          console.log("Playing audio track for user", user.uid);
        }
      } catch (error) {
        console.error("Error subscribing to user media:", error.message);
      }
    });

    // Handle when a user unpublishes their audio or video
    client.on("user-unpublished", (user, mediaType) => {
      setRemoteUsers((prevUsers) =>
        prevUsers.map((u) => {
          if (u.uid === user.uid) {
            if (mediaType === "video") {
              u.videoMuted = true;
            } else if (mediaType === "audio") {
              if (u.audioTrack) {
                u.audioTrack.stop(); // Stop the audio playback
              }
              u.audioMuted = true;
              u.audioTrack = null; // Clear the audioTrack reference
            }
          }
          return u;
        })
      );
    });

    // Handle when a user leaves the channel
    client.on("user-left", (user) => {
      setRemoteUsers((prevUsers) =>
        prevUsers.filter((u) => u.uid !== user.uid)
      );
    });

    let speakingTimeout;

    // client.on("volume-indicator", (volumes) => {
    //   volumes.forEach((volumeInfo) => {
    //     const { uid, level } = volumeInfo; // 'level' yahan volume level ko represent karta hai (0 se 255 tak)
    //     if (uid === userId && level > 60) {
    //       // Agar volume level 60 se zyada hai, toh matlab audio hai
    //       console.log("Aapki audio send ho rahi hai.");
    //     } else {
    //       console.log("Aapki audio detect nahi ho rahi.");
    //     }
    //   });
    // });
  };

  // Toggle local audio
  const toggleAudio = async () => {

    try {

      if (audioMuted) {
        // Pehle check karein agar pehli audio track exist karti hai toh usay stop aur close karein
        if (localTracks.audioTrack) {
          await rtcClient.unpublish(localTracks.audioTrack);
          localTracks.audioTrack.stop();
          localTracks.audioTrack.close();
          console.log("Old audio track stopped and closed.");
        }

        // Nayi audio track create karein aur publish karein
        const newAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: "speech_standard",
        });
        await rtcClient.publish(newAudioTrack);
        setLocalNewAudioTrack(newAudioTrack);
        // Nayi audio track ko state mein set karein
        setLocalTracks((prevTracks) => ({
          ...prevTracks,
          audioTrack: newAudioTrack,
        }));
        console.log("New audio track created and published.");
      } else {
        // Jab mute karna ho toh track ko unpublish, stop aur close karein
        if (localTracks.audioTrack) {
          await rtcClient.unpublish(localTracks.audioTrack);
          localTracks.audioTrack.stop();
          localTracks.audioTrack.close();
          setLocalTracks((prevTracks) => ({
            ...prevTracks,
            audioTrack: null,
          }));
          console.log("Audio track unpublished, stopped, and closed.");
        }
      }

      // State update karna
      setIsSpeaking(false);
      setAudioMuted((prev) => !prev);

      // Notification
      messageApi.destroy();
      messageApi.open({
        type: audioMuted ? "success" : "error",
        content: audioMuted ? "Microphone unmuted" : "Microphone muted",
        icon: audioMuted ? <AudioOutlined /> : <AudioMutedOutlined />,
      });
      setIsAudioLoader(false);
    } catch (error) {
      console.error("Error toggling audio:", error.message);
      setIsAudioLoader(false);
    }
  };

  // Toggle local video
  const toggleVideo = async () => {
    console.log("-----------------localTracks-------------", localTracks);
    try {
      if (videoMuted) {
        // Enable the video track
        await localTracks.videoTrack.setEnabled(true);
        // Publish the video track
        await rtcClient.publish(localTracks.videoTrack);
        console.log("Video track enabled and published.");
      } else {
        // Unpublish the video track
        await rtcClient.unpublish(localTracks.videoTrack);
        // Disable the video track
        await localTracks.videoTrack.setEnabled(false);
        console.log("Video track unpublished and disabled.");
      }

      setVideoMuted((prev) => !prev);
      messageApi.destroy();
      messageApi.open({
        type: videoMuted ? "success" : "error",
        content: videoMuted ? "Video Started" : "Video Stopped",
        icon: videoMuted ? (
          <Icon component={VideoStartSvg} />
        ) : (
          <Icon component={VideoStopSvg} />
        ),
      });
    } catch (error) {
      console.log("Error toggling video:", error.message);
    }
  };

  // Handle the raise hand logic in the parent component
  const handleRaiseHand = async () => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Toggle the hand raise state
    setIsHandRaised((prev) => !prev);
    message.destroy();

    if (!isHandRaised) {
      // Show message when hand is raised
      let user = seminarDetails?.seminar?.user;

      const payload = {
        type: "raise-hand",
        full_name: user.full_name,
        profile_photo: user.profile_photo,
        user_id: user.id,
      };
      const raiseHandMessage = JSON.stringify(payload);
      try {
        const result = await rtmClient.publish(channelName, raiseHandMessage);
        console.log("Hand raised:", result);
        message.info("Your hand will be lowered after 5 seconds.");
        const audio = new Audio("/meet-message-sound.mp3"); // Path from the public folder
        audio.play();
      } catch (status) {
        console.log(status);
      }

      // Start a new timeout to lower the hand after 5 seconds
      timeoutRef.current = setTimeout(() => {
        setIsHandRaised(false);
        message.info("Your hand has been lowered automatically.");
        timeoutRef.current = null; // Reset the timeout reference
      }, 5000);
    }
  };

  // Initialize the RTM client

  const initializeRtm = async (
    appId,
    channelName,
    token,
    uid,
    newRtcClient,
    audioTrack
  ) => {
    const { RTM } = AgoraRTM;
    const rtmClient = new RTM(appId, uid.toString());
    setRtmClient(rtmClient);
    await rtmClient.login({ uid: uid.toString(), token });
    await rtmClient.subscribe(channelName);
    console.log("newRtcClient=======================initializeRtm", audioTrack);

    try {
      // Add event listeners for "raise hand"
      rtmClient.addEventListener("message", async (event) => {
        console.log("payload=======payload", event.message);
        let payload = JSON.parse(event.message);
        console.log("payload=======payload", payload);
        let userId = payload.uId;

        if (payload.type === "raise-hand") {
          if (event.publisher !== uid.toString() && uid.toString() == uid) {
            const audio = new Audio("/meet-message-sound.mp3"); // Path from the public folder
            audio.play();
            message.success(`${payload.full_name} has raised their hand`, 5);
          }
        } else if (payload.type === "mute-by-admin") {
          if (userId == uid.toString()) {
            message.warning(`The host has muted you`, 5);

            setIsMuteByAdmin(true); // Set admin mute state

            console.log("LocalTracks LocalTracks LocalTracks", localTracks);
            // Ensure localTracks and audioTrack are defined before attempting to unpublish
            if (audioTrack) {
              await newRtcClient.unpublish(audioTrack);
              audioTrack.stop();
              audioTrack.close();
              await newRtcClient.unpublish(localNewAudioTrack);
              // localNewAudioTrack.stop();
              // localNewAudioTrack.close();
              setAudioMuted(true); // Fallback to muted state if microphone fails
            } else {
              console.log("localTracks or audioTrack is not available");
            }
          }
        } else if (payload.type === "unmute-by-admin") {
          if (userId == uid.toString()) {
            message.success(
              `The host has unmuted you`, // Updated message
              5
            );

            setIsMuteByAdmin(false);
            if (audioTrack) {
              console.log("LocalTracks LocalTracks LocalTracks", localTracks);
              console.log("Unpublishing local audio track", audioTrack);
              await newRtcClient.unpublish(audioTrack);
              audioTrack.stop();
              audioTrack.close();
              await newRtcClient.unpublish(localNewAudioTrack);
              // localNewAudioTrack.stop();
              // localNewAudioTrack.close();
              setAudioMuted(true); // Fallback to muted state if microphone fails
            } else {
              console.log("localTracks or audioTrack is not available");
            }
          }
          // await localTracks.audioTrack.setEnabled(false);
        } else if (payload.type === "screen-share") {
          if (payload.action === "start") {
            setScreenSharingUserId(userId);
          } else if (payload.action === "stop") {
            setScreenSharingUserId(null);
            setPinnedUser(null);
            message.info("Screen sharing stopped by the host.");
          }
        }
      });
    } catch (error) {
      console.log("RTM Message Event", error.message);
    }

    // Presence event handler
    rtmClient.addEventListener("presence", (event) => {
      if (event.eventType === "SNAPSHOT") {
        showMessage("INFO", "I Join");
      } else {
        showMessage("INFO", event.publisher + " is " + event.type);
      }
    });

    // Connection state changed event handler
    rtmClient.addEventListener("status", (event) => {
      showMessage("INFO", JSON.stringify(event));
    });

    const showMessage = (message) => {
      console.log(message);
      console.log("=========", remoteUsers);
    };
  };

  useEffect(() => {
    initializeAgora(
      "6411478804ef4f838f9f1ca8e5c9aa96",
      channelName,
      seminarAgoraToken,
      userId
    );

    return () => {
      if (joined) {
        rtcClient.leave();
      }
    };
  }, []);

  const toggleFullScreen = () => {
    const elem = videoContainerRef.current; // Get the video container element

    if (!document.fullscreenElement) {
      // Enter full-screen mode
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        // IE/Edge
        elem.msRequestFullscreen();
      }
      setFullScreen(true); // Set the state to full-screen mode
    } else {
      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
      setFullScreen(false); // Set the state to exit full-screen mode
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setFullScreen(false);
      } else {
        setFullScreen(true);
      }
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  const isScreenSharingSupported = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return !!navigator.mediaDevices.getDisplayMedia; // Check for Android support
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return false; // iOS does not support screen sharing
    }
    return true;
  };

  const toggleScreenShare = async () => {
    if (!isScreenSharingSupported()) {
      message.info("Screen sharing is not supported on this device.");
      return;
    }
    if (isSharing) {
    } else {
      // Start screen sharing
      try {
        const screenTrack = await AgoraRTC.createScreenVideoTrack({
          encoderConfig: "1080p_1",
          screenAudio: false,
        });

        screenTrack.on("track-ended", async () => {
          await rtcClient.unpublish(screenTrack);
          screenTrack.stop();
          setScreenTrack(null);
          setIsSharing(false);
          // Send RTM message to notify audience that screen sharing has stopped
          const payload = { type: "screen-share", action: "stop", uId: userId };
          const message = JSON.stringify(payload);

          try {
            await rtmClient.publish(channelName, message);
            console.log("Screen share stop message sent.");
          } catch (error) {
            console.error("Failed to send screen share stop message:", error);
          }
        });

        try {
          await rtcClient.unpublish(localTracks.videoTrack);
          await localTracks.videoTrack.setEnabled(false);
          setVideoMuted(true);
        } catch (error) {
          console.log("Video track unpublished and disabled.", error.message);
        }

        setScreenTrack(screenTrack);
        await rtcClient.publish(screenTrack);
        console.log("Screen sharing started.");
        setIsSharing(true);
        // Send RTM message to notify audience that screen sharing has stopped
        const payload = { type: "screen-share", action: "start", uId: userId };
        const message = JSON.stringify(payload);

        try {
          await rtmClient.publish(channelName, message);
          console.log("Screen share Start message sent.");
        } catch (error) {
          console.error("Failed to send screen share Start message:", error);
        }
      } catch (err) {
        console.error("Error starting screen sharing:", err);
      }
    }
  };
  useEffect(() => {
    if (screenTrack) {
      screenTrack.play("screen-share-container");
    }
  }, [screenTrack]);

  // Unmute participant's audio
  const unmuteParticipant = async (participant) => {
    const { uid } = participant;
    const payload = {
      type: "unmute-by-admin",
      uId: uid,
    };
    const raiseHandMessage = JSON.stringify(payload);
    try {
      const result = await rtmClient.publish(channelName, raiseHandMessage);
      message.info(`User ${participant.name} has been unmuted by the admin.`);
      setRemoteUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.uid === uid
            ? {
                ...u,
                is_admin_mute: false,
              }
            : u
        )
      );
    } catch (status) {
      console.log(status);
    }
  };

  // Mute participant's audio
  // Mute participant's audio
  const muteParticipant = async (participant) => {
    const { uid } = participant;

    const payload = {
      type: "mute-by-admin",
      uId: uid,
    };
    const raiseHandMessage = JSON.stringify(payload);
    try {
      const result = await rtmClient.publish(channelName, raiseHandMessage);
      message.info(`User ${participant.name} has been muted by the admin.`);
      setRemoteUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.uid === uid
            ? {
                ...u,
                is_admin_mute: true,
              }
            : u
        )
      );
    } catch (status) {
      console.log(status);
    }
  };

  const toggleChat = () => {
    setIsParticipant(true);
    setIsChat((prev) => !prev);
    setNewMessageCount([]);
  };
  const items = [
    {
      key: "1",
      label: (
        <HandRaiseControl
          isHandRaised={isHandRaised} // Pass the state
          toggleRaiseHand={handleRaiseHand} // Pass the toggle function
        />
      ),
    },
    {
      key: "2",
      label: (
        <ChatControl
          toggleChat={toggleChat}
          newMessageCount={newMessageCount}
        />
      ),
    },
    {
      key: "3",
      label: (
        <ScreenShareControl
          isSharing={isSharing}
          toggleScreenShare={toggleScreenShare}
        />
      ),
    },
    {
      key: "4",
      label: (
        <FullscreenControl
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
        />
      ),
    },
  ].filter((item) => {
    return item.key !== "3" || isHost;
  });

  return (
    <Layout style={{ height: "100vh" }} ref={videoContainerRef}>
      {contextHolder}
      {loading ? (
        <Card
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Spin size="large" />
          <Title level={3}>Seminar initialize....</Title>
        </Card>
      ) : (
        <>
          {agoraJoinRtc ? (
            <>
              <Layout>
                <Content>
                  {/* <div
                      id="screen-share-container"
                      style={{
                        width: "100%",
                        height: "400px",
                        background: "#000",
                      }}
                    >
                      {isSharing && <div>Screen Sharing...</div>}
                    </div> */}
                  <Row>
                    <Col xs={24}>
                      {/* Render the pinned user in full screen if available */}
                      {pinnedUser ? (
                        <div style={{ position: "relative" }}>
                          <div
                            id={`pinned-video-${pinnedUser.uid}`} // Unique ID for pinned user's video
                            style={{
                              width: "100%",
                              height: "calc(100vh - 60px)",
                            }}
                          />
                        </div>
                      ) : (
                        <HostVideo
                          videoMuted={videoMuted}
                          seminarDetails={seminarDetails}
                          localTracks={localTracks}
                          isSpeaking={isSpeaking}
                        />
                      )}
                    </Col>
                  </Row>
                </Content>

                <Sider
                  collapsed={isParticipant}
                  width={isTabletOrMobile ? "100%" : 400}
                  style={{ background: "#f0f2f5" }}
                  collapsedWidth={0}
                >
                  <ParticipantList
                    participants={remoteUsers}
                    // onPinParticipant={handlePinParticipant}
                    muteParticipant={muteParticipant}
                    unmuteParticipant={unmuteParticipant}
                    getQueryParams={getQueryParams}
                    setParticipantDataChat={setParticipantDataChat}
                    onClose={() => {
                      setIsParticipant(true);
                    }}
                  />
                </Sider>
                <Sider
                  collapsed={isChat}
                  width={isTabletOrMobile ? "100%" : 400}
                  style={{ background: "#f0f2f5" }}
                  collapsedWidth={0}
                >
                  {userId && (
                    <Chat
                      participants={remoteUsers}
                      channelName={channelName}
                      seminar={seminarDetails}
                      userId={userId.toString()}
                      token={seminarDetails.agoraChatToken}
                      participantDataChat={participantDataChat}
                      setNewMessageCount={setNewMessageCount}
                      onClose={() => {
                        setIsChat(true);
                      }}
                    />
                  )}
                </Sider>
              </Layout>

              <Footer className="footer">
                <AudioControl
                  audioMuted={audioMuted}
                  toggleAudio={toggleAudio}
                  isMuteByAdmin={isMuteByAdmin}
                  audioPermissionGranted={audioPermissionGranted}
                  isAudioLoader={isAudioLoader}
                  setIsAudioLoader={setIsAudioLoader}
                />
                <VideoControl
                  videoMuted={videoMuted}
                  toggleVideo={toggleVideo}
                />

                <Tooltip title={"Audience"}>
                  <Badge count={remoteUsers.length} offset={[0, 0]}>
                    <Button
                      shape="circle"
                      icon={<UserOutlined />}
                      size="large"
                      onClick={() => {
                        setIsChat(true);
                        setIsParticipant((prev) => !prev);
                      }}
                    />
                  </Badge>
                </Tooltip>

                <Dropdown
                  trigger={"click"}
                  menu={{ items }}
                  placement="topRight"
                  rootClassName={"footer-menu-more"}
                  getPopupContainer={() => videoContainerRef.current}
                >
                  <Badge dot={newMessageCount.length}>
                    <Button
                      icon={<MoreOutlined />}
                      shape="circle"
                      size="large"
                    />
                  </Badge>
                </Dropdown>
              </Footer>
            </>
          ) : (
            <Content
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Result
                status="error"
                title="Unable to Join"
                subTitle="We encountered an issue. Please contact the administrator."
              />
            </Content>
          )}
        </>
      )}
    </Layout>
  );
};

export default SeminarStream;
