import React from 'react';
import { Tooltip, Button } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';

const FullscreenControl = ({ isFullScreen, toggleFullScreen }) => {
  return (
    <Tooltip title={isFullScreen ? "Exit Fullscreen" : "Fullscreen"}>
      <Button
        shape="circle"
        size="large"
        style={{
          backgroundColor: isFullScreen && '#52c41a', // Red if hand is raised, green if lowered
          color: isFullScreen && '#fff',
        }}
        icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
        onClick={toggleFullScreen}
      />
    </Tooltip>
  );
};

export default FullscreenControl;
