import React, { useEffect, useState } from "react";
import { Avatar, Space } from "antd";
import { Typography } from "antd";
import "./HostVideo.css"; // Assuming you create this CSS file for styling

const { Title } = Typography;

const HostVideo = ({ videoMuted, seminarDetails, localTracks, isSpeaking }) => {
  const [videoStream, setVideoStream] = useState(false);

  useEffect(() => {
    if (localTracks?.videoTrack && !videoMuted) {
      setVideoStream(true);
    } else if (localTracks?.videoTrack && videoMuted) {
      localTracks.videoTrack.stop();
      setVideoStream(false);
    }
  }, [localTracks, videoMuted]);

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("");
  };

  const profileImage = seminarDetails?.seminar?.user?.profile_photo;

  return (
    <div
      className={`host-video`} // Add "speaking" class when the user is speaking
      style={{
        height: "calc(100vh - 60px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* If video is muted or unavailable, show the host's avatar and name */}
      {videoMuted || !localTracks?.videoTrack ? (
        <Space direction="vertical" align="center">
          <Avatar
            size={100}
            src={<img src={profileImage} alt="Host" />}
            className={isSpeaking ? "avatar-speaking" : ""} // Apply speaking class to avatar
          >
            {getInitials(seminarDetails?.seminar?.user?.full_name || "Host")}
          </Avatar>
          <Title level={5} style={{ marginTop: 0 }}>
            {seminarDetails?.seminar?.user?.full_name || "Host Name"}
          </Title>
        </Space>
      ) : (
        <div
          id="local-player"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000",
          }}
        >
          {localTracks.videoTrack.play("local-player")}
        </div>
      )}
    </div>
  );
};

export default HostVideo;
