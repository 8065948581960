import React, { useState, useEffect } from "react";
import { Button, Typography, Spin, Card, Result } from "antd";
import { usePermission } from "../Permissions/PermissionContext";
import axios from "axios";
import dayjs from "dayjs"; // To compare seminar date
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import SeminarStream from "./SeminarStream";

// Extend dayjs with the plugin
dayjs.extend(isSameOrAfter);

const { Title } = Typography;

const Seminar = () => {
  const [seminarDetails, setSeminarDetails] = useState(null);
  const [seminarAgoraToken, setSeminarAgoraToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [paymentError, setPaymentError] = useState(null);

  // Function to get query parameters from the URL
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  // Fetch seminar details using Axios and send the URL parameters
  const fetchSeminarDetails = async () => {
    const urlParams = getQueryParams(); // Get all URL params

    // Get the token from URL params (e.g., ?token=your_token)
    const token = urlParams.token;

    try {
      const response = await axios.get(
        "https://api.gorillafilm.com/api/user/seminar/channel",
        {
          params: urlParams, // Send URL params in the request
          headers: {
            Authorization: `Bearer ${token}`, // Send the token in the Authorization header
          },
        }
      );
      setSeminarDetails(response.data);
      setSeminarAgoraToken(response.data.agorraToken);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching seminar details:", err);
      setError(err.response?.data?.message || "Failed to load seminar details");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSeminarDetails();
  }, []);

  // Function to check if the seminar date is valid (today or in the future)
  const isSeminarDateValid = () => {
    if (!seminarDetails?.seminar?.seminar?.seminar_date) return false;
    const currentDate = dayjs(); // Current date
    const seminarDate = dayjs(seminarDetails?.seminar?.seminar?.seminar_date); // Seminar date
    return seminarDate.isSameOrAfter(currentDate, "day"); // Check if seminar is today or in the future
  };

  // Check both date validity and payment status
  useEffect(() => {
    if (seminarDetails && seminarDetails.role === "audience") {
      if (!loading && seminarDetails?.seminar?.seminar?.seminar) {
        if (!isSeminarDateValid()) {
          setDateError("The seminar date is invalid or has already passed.");
        } else {
          setDateError(null);
        }

        if (!seminarDetails?.seminar?.seminar.has_paid) {
          setPaymentError(
            "Payment is not successful. Please complete the payment."
          );
        } else {
          setPaymentError(null);
        }
      }
    }
  }, [loading, seminarDetails]);

  if (loading) {
    return (
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Spin size="large" />
        <Title level={3}>Loading seminar details...</Title>
      </Card>
    );
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Failed to load seminar details"
        subTitle={error}
        extra={[
          <Button type="primary" key="reload" onClick={fetchSeminarDetails}>
            Retry
          </Button>,
        ]}
      />
    );
  }

  return (
    <Card
      bordered={false}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <>
        {/* Check for Date Error */}
        {dateError && (
          <Result
            status="error"
            title="Date Error"
            subTitle={dateError}
            extra={[
              <Button
                type="primary"
                key="checkAgain"
                onClick={fetchSeminarDetails}
              >
                Retry
              </Button>,
            ]}
          />
        )}

        {/* Check for Payment Error */}
        {paymentError && (
          <Result
            status="warning"
            title="Payment Error"
            subTitle={paymentError}
            extra={[
              <Button type="primary" key="retryPayment">
                Complete Payment
              </Button>,
            ]}
          />
        )}

        {/* If no errors, show seminar screen */}
        {!dateError && !paymentError ? (
          <div>
            <SeminarStream
              seminarDetails={seminarDetails}
              seminarAgoraToken={seminarAgoraToken}
              getQueryParams={getQueryParams}
            />
          </div>
        ) : null}
      </>
    </Card>
  );
};

export default Seminar;
