import React, { createContext, useState, useEffect, useContext } from "react";
import { Modal, Button, notification } from "antd";

// Create the context
const PermissionContext = createContext();

// Custom hook to access the Permission context easily
export const usePermission = () => {
  return useContext(PermissionContext);
};

// Provider component that wraps around the app
export const PermissionProvider = ({ children }) => {
  const [audioPermissionGranted, setAudioPermissionGranted] = useState(null);
  const [videoPermissionGranted, setVideoPermissionGranted] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to check microphone permissions
  const checkAudioPermission = async () => {
    try {
      // Request permission for audio only
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (stream) {
        setAudioPermissionGranted(true);

        // notification.success({
        //   message: "Audio Permission Granted",
        //   description: "You have granted access to the microphone.",
        //   duration: 3,
        // });
      }
    } catch (err) {
      handleAudioPermissionError(err);
    }
  };

  // Function to check video permissions
  const checkVideoPermission = async () => {
    try {
      // Request permission for video only
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setVideoPermissionGranted(true);

        // notification.success({
        //   message: "Video Permission Granted",
        //   description: "You have granted access to the camera.",
        //   duration: 3,
        // });
      }
    } catch (err) {
      handleVideoPermissionError(err);
    }
  };

  // Function to handle specific permission errors for audio
  const handleAudioPermissionError = (err) => {
    console.error("Audio permission denied:", err);

    if (err.name === "NotAllowedError") {
      notification.error({
        message: "Microphone Access Blocked",
        description: "Please allow access to your microphone to continue.",
        duration: 5,
      });
    } else if (err.name === "NotFoundError") {
      notification.error({
        message: "Microphone Not Detected",
        description: "Please ensure your microphone is connected.",
        duration: 5,
      });
    } else if (
      err.name === "NotReadableError" ||
      err.name === "OverconstrainedError"
    ) {
      notification.warning({
        message: "Microphone Issue Detected",
        description: "Please check your microphone hardware.",
        duration: 5,
      });
    } else {
      notification.error({
        message: "Microphone Permission Error",
        description: "Please try again or check your browser settings.",
        duration: 5,
      });
    }
    setAudioPermissionGranted(false);
  };

  // Function to handle specific permission errors for video
  const handleVideoPermissionError = (err) => {
    console.error("Video permission denied:", err);

    if (err.name === "NotAllowedError") {
      notification.error({
        message: "Camera Access Blocked",
        description: "Please allow access to your camera to continue.",
        duration: 5,
      });
    } else if (err.name === "NotFoundError") {
      notification.error({
        message: "Camera Not Detected",
        description: "Please ensure your camera is connected.",
        duration: 5,
      });
    } else if (
      err.name === "NotReadableError" ||
      err.name === "OverconstrainedError"
    ) {
      notification.warning({
        message: "Camera Issue Detected",
        description: "Please check your camera hardware.",
        duration: 5,
      });
    } else {
      notification.error({
        message: "Camera Permission Error",
        description: "Please try again or check your browser settings.",
        duration: 5,
      });
    }
    setVideoPermissionGranted(false);
  };

  // Call both permission checks when the provider is mounted
  useEffect(() => {
    checkAudioPermission();
    checkVideoPermission();
  }, []);

  // Modal for permission errors
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <PermissionContext.Provider
      value={{
        audioPermissionGranted,
        videoPermissionGranted,
        checkAudioPermission,
        checkVideoPermission,
        isModalOpen,
        handleCancel,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};
