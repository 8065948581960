import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Button,
  List,
  Avatar,
  Typography,
  message as AntMessage,
  Flex,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import AC from "agora-chat"; // Agora Chat SDK
import moment from "moment"; // For timestamps
import { CloseOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Text } = Typography;

const Chat = ({
  participantDataChat,
  userId,
  token,
  seminar,
  setNewMessageCount,
  onClose,
}) => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [connected, setConnected] = useState(false); // Track connection state
  const messageEndRef = useRef(null);

  // Agora connection instance
  const conn = useRef(null);

  // Initialize Agora connection
  useEffect(() => {
    // Set up Agora connection
    conn.current = new AC.connection({
      appKey: "411211938#1401193", // Replace with your actual Agora app key
      isDebug: true, // Enable debug logging to see more detailed logs
    });

    // Event Handlers for Agora Chat
    conn.current.addEventHandler("connection&message", {
      onConnected: () => {
        setConnected(true);
        AntMessage.success("Connected to Agora Chat");
      },
      onDisconnected: () => {
        setConnected(false);
        AntMessage.error("Disconnected from Agora Chat");
      },
      onTextMessage: (message) => {
        const newMessage = {
          sender: message.from,
          content: message.msg,
          timestamp: moment().format("hh:mm A"),
        };

        setNewMessageCount((prevMessages) => [...prevMessages, newMessage]);

        setMessages((prevMessages) => [...prevMessages, newMessage]);
      },
      onTokenWillExpire: () => {
        AntMessage.warning("Token is about to expire");
      },
      onTokenExpired: () => {
        AntMessage.error("Token has expired");
      },
      onError: (error) => {
        console.error("Agora Chat error:", error); // Enhanced logging
        if (error.type === 1) {
          AntMessage.error("Network error, please check your connection.");
        } else if (error.type === 2) {
          AntMessage.error(
            "Authorization failed, please check your app key or token."
          );
        } else if (error.type === 3) {
          AntMessage.error("Agora Chat server error.");
        } else {
          AntMessage.error("An unknown error occurred.");
        }
      },
    });

    // Log into Agora Chat (User authentication)
    conn.current.open({
      user: userId,
      agoraToken: token,
    });

    return () => {
      conn.current && conn.current.close(); // Clean up on component unmount
    };
  }, [userId, token]); // Ensure useEffect runs again if userId or token changes

  // Function to send a group message
  const handleSendMessage = () => {
    if (currentMessage.trim()) {
      var agora_group_id;
      if (seminar.role === "audience") {
        agora_group_id = seminar?.seminar?.seminar?.agora_group_id;
      } else {
        agora_group_id = seminar?.seminar?.agora_group_id;
      }
      const msgOption = {
        chatType: "groupChat", // Group chat
        type: "txt",
        to: agora_group_id, // Replace with actual group ID
        msg: currentMessage,
      };
      let msg = AC.message.create(msgOption);

      conn.current
        .send(msg)
        .then(() => {
          const newMessage = {
            sender: "Me",
            content: currentMessage,
            timestamp: moment().format("hh:mm A"),
          };
          setMessages([...messages, newMessage]);
          setCurrentMessage("");
        })
        .catch((e) => {
          alert(e.message);
          AntMessage.error("Failed to send message");
        });
    }
  };

  // Auto-scroll to the latest message
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [messages]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          textAlign: "center",
        }}
      >
        <Flex justify={"space-between"} align={"center"}>
          <h3>Chat</h3>
          <Button
            icon={<CloseOutlined />}
            danger
            type="primary"
            shape="circle"
            onClick={onClose}
          />
        </Flex>
        {/* {connected ? (
          <Text type="success"> Connected to Agora Chat </Text>
        ) : (
          <Text type="danger"> Not connected </Text>
        )} */}
      </div>
      {/* Message List */}
      <div
        style={{
          flexGrow: 1,
          overflowY: "auto",
          padding: "16px",
        }}
      >
        <List
          itemLayout="horizontal"
          dataSource={messages}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                className="list-item-meta-description"
                // avatar={<Avatar> {item.sender[0]} </Avatar>}
                title={
                  <>
                    <Text strong>
                      {" "}
                      {item.sender === "Me"
                        ? "Me"
                        : participantDataChat[item.sender].name}{" "}
                    </Text>
                    <Text type="secondary"> {item.timestamp} </Text>
                  </>
                }
                description={item.content}
              />
            </List.Item>
          )}
        />
        <div ref={messageEndRef} />
      </div>
      {/* Input and Send Button */}
      <div
        style={{
          display: "flex",
          padding: "16px",
          borderTop: "1px solid #f0f0f0",
        }}
      >
        <TextArea
          rows={1}
          placeholder="Type a message..."
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          onPressEnter={handleSendMessage} // Send message on Enter key
          style={{
            flexGrow: 1,
            marginRight: "8px",
          }}
        />
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={handleSendMessage}
          disabled={!currentMessage.trim()}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default Chat;
